import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { TrackJS } from "trackjs";
import { Relations } from "utils/types";
import useApi from "api";
import RetailNotification from "components/Notification";

const useTableStatusChange = (
  /**
   * Url for api call.
   * For non relation tables no need to pass id but relation tables needs campaign id as well.
   */
  url: string,
  /**
   * Determines if a table relation or not.
   */
  isRelation: boolean,
  /**
   * If the table is a relation table, relationType is a must.
   */
  relationType?: Relations
) => {
  const { t } = useTranslation();

  const { api, role, isLimitedUser, baseURL } = useApi();

  //Config type for non relation active/pause tables
  const notificationType = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "PAUSED";
      case "PAUSED":
        return "ACTIVE";
      default:
        return "ACTIVE";
    }
  };

  //Config type differs in relation tables.
  const switchConfig = (data: any, status: string) => {
    const statusForUpdate = { status: notificationType(status) };
    switch (relationType) {
      case "PRODUCTS":
        return {
          campaign_advertiser_products: {
            ...statusForUpdate,
            id: [data.product_id],
          },
        };
      case "CREATIVES":
        return {
          campaign_creatives: { ...statusForUpdate, id: [data.creative_id] },
        };
      case "KEYWORDS":
        return {
          campaign_keywords: {
            ...statusForUpdate,
            keywords: [{ id: data.keyword_id }],
          },
        };
      case "NEGATIVE_KEYWORDS":
        return {
          campaign_negative_keywords: {
            ...statusForUpdate,
            id: [data.keyword_id],
          },
        };
      case "CITIES":
        return { campaign_cities: { ...statusForUpdate, id: [data.city_id] } };
      case "AGE_RANGES":
        return {
          campaign_age_ranges: { ...statusForUpdate, id: [data.age_range_id] },
        };
      case "GENDERS":
        return {
          campaign_genders: { ...statusForUpdate, id: [data.gender_id] },
        };
      case "PLATFORMS":
        return {
          campaign_platforms: { ...statusForUpdate, id: [data.platform_id] },
        };
      case "CUSTOMERS":
        return {
          campaign_customers: {
            ...statusForUpdate,
            id: [data.customer_targeting_id],
          },
        };
      case "PLACEMENTS":
        return {
          campaign_placements: { ...statusForUpdate, id: [data.placement_id] },
        };
      case "CATEGORIES":
        return {
          campaign_categories: {
            ...statusForUpdate,
            categories: [{ id: data.category_id }],
          },
        };
      case "NEGATIVE_CATEGORIES":
        return {
          campaign_negative_categories: {
            ...statusForUpdate,
            id: [data.category_id],
          },
        };
      case "SEGMENTS":
        return {
          campaign_segments: { ...statusForUpdate, id: [data.segment_id] },
        };
    }
  };

  //Update function
  const updateValue = async (data: any) => {
    const response = await api({
      //Method type differs for relation and non relation tables.
      method: isRelation ? "post" : "patch",
      //Same goes for url. For non relation table url passed directly as parameter.
      url: isRelation ? url : `${url}/${data.id || data.account_user_id}`,
      //Data config logic
      data: isRelation
        ? switchConfig(data, data.status || data.account_user_status)
        : {
            status: notificationType(data.status || data.account_user_status),
          },
    });
    return response;
  };

  const { mutateAsync, isLoading } = useMutation(updateValue);

  const queryClient = useQueryClient();

  const notificationTextValue = isRelation
    ? t(`components.notification.${relationType?.toLowerCase()}`)
    : t(`components.notification.${url.replace("-", "_")}`);

  const statusError = (err: any) => {
    if (err.request.responseText.includes("at least one")) return "must";
    else if (err.request.responseText.includes("rating is below min rating"))
      return "rating";
    else if (err.request.responseText.includes("stock is below zero"))
      return "stock";
    else if (err.request.responseText.includes("not buy box")) return "buybox";
    else if (err.request.responseText.includes("product status is not active"))
      return "notActive";
    else if (err.request.responseText.includes("pending creative"))
      return "pending";
    else if (err.request.responseText.includes("rejected creative"))
      return "rejected";
    else return "err";
  };

  const changeValue = async (data: any) => {
    const successText = t(
      `components.notification.${notificationType(
        data.status || data.account_user_status
      ).toLowerCase()}`,
      {
        value: notificationTextValue,
      }
    );
    try {
      await mutateAsync(data);
      isRelation
        ? queryClient.refetchQueries(["table", { type: relationType }])
        : queryClient.refetchQueries(["table", { url }]);
      RetailNotification.showNotification("success", "", successText);
    } catch (error) {
      TrackJS.track({
        message: `Table Status Change Error ${
          isRelation ? relationType : ""
        } ${url}`,
        metadata: {
          endpoint: isRelation
            ? url
            : `${url}/${data.id || data.account_user_id}`,
          role,
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          requestBody: isRelation
            ? switchConfig(data, data.status || data.account_user_status)
            : {
                status: notificationType(
                  data.status || data.account_user_status
                ),
              },
          responseBody: error?.response?.data,
          statusCode: error?.response?.status,
          errorMessage: error?.message,
          severity: "Low",
        },
      });
      switch (statusError(error)) {
        case "must":
          return RetailNotification.showNotification(
            "error",
            "",
            t(`pages.acc.campaignDetails.${relationType!.toLowerCase()}Error`)
          );
        case "err":
          return RetailNotification.showNotification(
            "error",
            "",
            t("components.notification.statusError")
          );
        default:
          return RetailNotification.showNotification(
            "error",
            "",
            t(`pages.acc.campaignDetails.${statusError(error)}Error`)
          );
      }
    }
  };

  return { changeValue, isLoading };
};

export default useTableStatusChange;
