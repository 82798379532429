import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import Modal, { ModalProps } from "antd/lib/modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Auth, AuthContext } from "context/AuthProvider";
import { ReactComponent as CheckOutlined } from "assets/icons/checkSuccessOutlined.svg";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";

import cm from "./style.module.scss";

export interface RetailCampaignModalHandles {
  open(): void;
  close(): void;
}

export interface RetailCampaignModalProps extends ModalProps {
  navigateData: {
    campaign_id: string;
    campaign_name: string;
    advertiser_id: string;
    ad_type: string;
  };
  onClick(): void;
  isAdmin: boolean;
}

const RetailCampaignModal: React.ForwardRefRenderFunction<
  RetailCampaignModalHandles,
  RetailCampaignModalProps
> = ({ navigateData, onClick, isAdmin }, ref) => {
  const { t } = useTranslation();

  const { advValue } = useContext(AuthContext) as Auth;

  let navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  const close = () => setIsVisible(false);

  const open = () => setIsVisible(true);

  const okay = () => {
    setIsVisible(false);

    const { campaign_id, campaign_name, advertiser_id, ad_type } = navigateData;

    const pathObjAdvView = `/campaign/${campaign_id}?adv=${advertiser_id}`;

    const link = advValue ? pathObjAdvView : `/campaign/${campaign_id}`;

    navigate(
      {
        pathname: isAdmin ? pathObjAdvView : link,
      },
      {
        state: {
          breadcrumb: true,
          breadcrumbType: "campaign",
          value: campaign_name,
          ad_type: ad_type,
        },
      }
    );
    onClick();
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Modal
      visible={isVisible}
      className={cm.modal}
      onOk={close}
      onCancel={close}
      centered
      maskClosable={false}
      footer={[
        <RetailMainButton hasBackground={true} onClick={okay} className={cm.ok}>
          {t("common.okay")}
        </RetailMainButton>,
      ]}
    >
      <div className={cm.container} data-cy="campaign-model">
        <div className={cm.check}>
          <CheckOutlined />
        </div>
        <RetailTitle className={cm.title} level={3}>
          {t("components.modal.campaign.success")}
        </RetailTitle>
        <div className={cm.content}>
          <RetailText family="poppins" size="xxs" className={cm.popupText}>
            {t("components.modal.campaign.successText")}
          </RetailText>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(RetailCampaignModal);
