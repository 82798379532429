import {
  Input,
  RadioChangeEvent,
  Tabs,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/lib/upload/interface";
import useApi from "api";
import { ReactComponent as VideoUploadBackground } from "assets/icons/videoUploadBackground.svg";
import { ReactComponent as CreativeIcon } from "assets/images/creatives.svg";
import cc from "classcat";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailDrawer from "components/Drawer/RetailDrawer";
import RetailFormInfo from "components/Form/RetailFormInfo";
import RetailFormRow from "components/Form/RetailFormRow";
import CreativePreviewModal from "components/Modal/CreativePreviewModal";
import RetailNotification from "components/Notification";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import {
  Creative,
  CreativeContext,
} from "context/CreativeProvider";
import {
  ChangeEvent,
  ClipboardEvent,
  useContext,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  ACCEPTED_FILE_TYPES,
  UPLOAD_FORMATS,
} from "utils/constants";
import { selectCampaignType } from "utils/helpers";
import {
  AdType,
  Img,
  Video,
} from "utils/types";

import CreativeNameForm from "./CreativeNameForm";
import CreativeStepOptions from "./CreativeStepOptions";
import DisplayItem from "./DisplayItem";
import SecondDrawerUploadInner from "./SecondDrawerUploadInner";
import cm from "./style.module.scss";
import VideoItem from "./VideoItem";

export interface CreativeStepProps {
  type: AdType;
  targeting: string;
  error: boolean;
  languages: string[];
  allowHtml?: boolean;
}

const CreativeStep = ({
  type,
  error,
  targeting,
  languages,
  allowHtml,
}: CreativeStepProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const mainImgFile = useRef<File>();

  const { video, setVideo, deleteVideo, img, setImg, deleteCreative } =
    useContext(CreativeContext) as Creative;

  const [openUpload, setOpenUpload] = useState(true);

  const [isMultipleFilesSupported, setIsMultipleFilesSupported] =
    useState(false);

  const [firstDrawerStates, setFirstDrawerStates] = useState({
    visible: false,
    selected: "",
  });

  const [secondDrawerStates, setSecondDrawerStates] = useState<{
    visible: boolean;
    name: string;
    showCreative: boolean;
    isLoading: boolean;
    size?: string;
    textValue?: string;
  }>({
    visible: false,
    name: "",
    showCreative: false,
    isLoading: false,
    size: "",
    textValue: "",
  });

  const [updateDrawerStates, setUpdateDrawerStates] = useState<{
    visible: boolean;
    uuid: string;
    name: string;
    creativeLanguages: string[] | null;
  }>({
    visible: false,
    uuid: "",
    name: "",
    creativeLanguages: null,
  });

  const [previewStates, setPreviewStates] = useState({
    visible: false,
    url: "",
    name: "",
    html: "",
    size: "",
  });

  const [inputError, setInputError] = useState(false);

  const isValidVAST =
    /https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9&%_./~-]*)?/;

  const validateInput = (input: string): { isValid: boolean; width?: number; height?: number } => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, "text/html");

    for (const meta of Array.from(doc.getElementsByTagName("meta"))) {
      const content = meta.getAttribute("content");
      const match = content?.match(/width=(\d+),height=(\d+)/);
      if (match) {
        const width = parseInt(match[1], 10);
        const height = parseInt(match[2], 10);
        return { isValid: true, width, height };
      }
    }

    return { isValid: false };
  };


  /*  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input, { USE_PROFILES: { html: true } });
  }; */

  /* const isSanitizedMatch = (input: string): boolean => {
    const sanitized = sanitizeInput(input);
    const normalizedInput = normalizeHtml(input);
    const normalizedSanitized = normalizeHtml(sanitized);
    console.log(sanitized,);
    return normalizedInput === input;
  }; */

  const [form] = useForm();

  const open = (url: string, name: string, html: string, size: string) =>
    setPreviewStates({ visible: true, url, name, html, size });

  const close = () =>
    setPreviewStates({ visible: false, url: "", name: "", html: "", size: "" });

  const showWrapper =
    video?.filter((v) => v.submitted)?.length > 0 ||
    img?.filter((i) => i.submitted)?.length > 0;

  const props: UploadProps = {
    name: "files",
    disabled: secondDrawerStates.isLoading,
    className: cc(["dragger", cm.creativeUploadDragger]),
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) =>
      handleFileChange(
        f,
        type === "VIDEO" ? "video" : "image",
        f.fileList.length > 1
      ),
    openFileDialogOnClick: openUpload,
    accept: ACCEPTED_FILE_TYPES[type === "VIDEO" ? "VIDEO" : "IMAGE"],
  };

  const uploadCreative = async (
    info: UploadChangeParam<UploadFile<any>> | null,
    options: {
      type: "image" | "video" | "vast" | "script";
      textValue?: string;
      name?: string;
      size?: string;
      onSuccess?: (response: any) => void;
      onError?: (error: any) => void;
    }
  ) => {
    const { type, textValue, name, size, onSuccess, onError } = options;

    const file = mainImgFile;
    if (type !== "vast" && type !== "script") {
      if (!info || !info.file || info.fileList.length === 0) {
        file.current = undefined;
        return;
      }
      file.current = info.file as unknown as File;
    }

    const formData = new FormData();

    formData.append("status", "ACTIVE");
    formData.append(
      "name",
      type === "vast" || type === "script"
        ? name!
        : file?.current?.name.replace(/\.[^/.]+$/, "") || ""
    );

    if (type === "image" || type === "video") {
      formData.append(type, file.current!);
      formData.append(
        "format",
        type === "image" && info?.file?.type === "image/gif"
          ? UPLOAD_FORMATS.GIF
          : UPLOAD_FORMATS[type.toUpperCase() as keyof typeof UPLOAD_FORMATS]
      );
    } else if (type === "vast") {
      formData.append("format", UPLOAD_FORMATS.VIDEO);
      formData.append("vast_tag", textValue!);
    } else if (type === "script") {
      formData.append("format", UPLOAD_FORMATS.IMAGE);
      formData.append("html", textValue!);
      formData.append("size", size!);
    }

    try {
      const response = await api.post(
        "/creatives",
        createConfig(formData, type),
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      await handleSuccess(
        response,
        type.toLowerCase() as "image" | "video",
        isMultipleFilesSupported
      );
      if (onSuccess) onSuccess(response);
    } catch (error) {
      if (onError) onError(error);
    }
  };
  const handleFileChange = async (
    info: UploadChangeParam<UploadFile<any>> | null,
    type: "video" | "image",
    isMultiple?: boolean
  ) => {
    if (!info) {
      console.warn("No file information provided.");
      return;
    }

    await uploadCreative(info, {
      type,
      onSuccess: () => {
        setSecondDrawerStates((prev) => ({
          ...prev,
          showCreative: isMultiple ? false : true,
          isLoading: false,
        }));
      },
      onError: (error) => handleError(error, type),
    });
  };

  const handleError = (
    error: any,
    type: "image" | "video" | "vast" | "script"
  ) => {
    console.error(error);
    setSecondDrawerStates((prev) => ({ ...prev, isLoading: false }));

    let errKey = "generalErr";

    if (type === "image" && error?.request?.responseText) {
      const responseText = error.request.responseText;
      errKey = responseText.includes("maximum number of creatives for SMO")
        ? "creativeLimitErr"
        : responseText.includes("file size is too large")
          ? "creativeSizeErr"
          : "generalErr";
    }

    RetailNotification.showNotification(
      "error",
      "",
      t(`components.campaignForm.firstStep.errorStates.${errKey}`)
    );
  };

  const createConfig = (
    formData: FormData,
    type: "image" | "video" | "vast" | "script"
  ) => {
    const key = type === "image" ? "image" : type === "video" ? "video" : null;

    return {
      ...(key && { [key]: formData.get(key) }),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        ...(type === "vast" && { vast_tag: formData.get("vast_tag") }),
        ...(type === "script" && {
          html: formData.get("html"),
          size: formData.get("size"),
        }),
      }),
    };
  };

  const resetUploadStates = (
    success: boolean,
    resetType?: "image" | "video" | "vast" | "script"
  ) => {
    const hasCreativeToDelete = video.length > 0 || img.length > 0;

    setFirstDrawerStates({ visible: false, selected: "" });

    setSecondDrawerStates({
      visible: false,
      name: "",
      showCreative: false,
      isLoading: false,
      textValue: "",
    });

    setInputError(false);

    form.resetFields();

    if (!success && hasCreativeToDelete) {
      if (resetType === "vast" || resetType === "script") return;
      switch (type) {
        case "VIDEO":
          deleteVideo(video[video.length - 1].uuid);
          break;
        case "DISPLAY":
          deleteCreative(img[img.length - 1].uuid);
          break;
        default:
          break;
      }
    }
  };

  const postVideoWithVast = async () => {
    await uploadCreative(null, {
      type: "vast",
      name: secondDrawerStates.name,
      textValue: secondDrawerStates.textValue,
      onSuccess: () => {
        resetUploadStates(true, "vast");
        RetailNotification.showNotification(
          "success",
          "",
          t("components.notification.creativeUpload"),
          "bottomLeft"
        );
      },
      onError: (error) => handleError(error, "vast"),
    });
  };

  const postCreativeWithScript = async () => {
    await uploadCreative(null, {
      type: "script",
      name: secondDrawerStates.name,
      textValue: secondDrawerStates.textValue,
      size: secondDrawerStates.size,
      onSuccess: () => {
        resetUploadStates(true, "script");
        RetailNotification.showNotification(
          "success",
          "",
          t("components.notification.creativeUpload"),
          "bottomLeft"
        );
      },
      onError: (error) => handleError(error, "script"),
    });
  };

  const handleSuccess = async (
    response: any,
    type: "image" | "video" | "vast" | "script",
    isMultiple?: boolean
  ) => {
    switch (type) {
      case "image":
        return new Promise<void>((resolve) => {
          const img = new Image();
          img.src = response.data.image_url;
          if (isMultiple && !response.data.size_valid) {
            RetailNotification.showNotification(
              "error",
              "",
              t(
                "components.campaignForm.firstStep.errorStates.creativeDimensionErr"
              )
            );
          } else {
            img.onload = () => {
              setImg((prev: Img[]) => [
                ...prev,
                {
                  url: img?.src,
                  size: `${img.width}x${img.height}`,
                  creative_name: response.data.creative_name,
                  uuid: response.data.id,
                  size_valid: response.data.size_valid,
                  languages: response?.data?.languages,
                  ...(isMultiple && { submitted: true }),
                  /*     ...(type === "script" && { submitted: true }), */
                },
              ]);
              resolve();
            };
          }
        });
      case "video":
      case "vast":
        return setVideo((prev: Video[]) => [
          ...prev,
          {
            url: response?.data?.image_url,
            size: response?.data?.size,
            creative_name: response?.data?.creative_name,
            uuid: response?.data?.id,
            languages: response?.data?.languages,
            submitted: type !== "vast" && isMultiple,
            ...(type === "vast" && {
              vast_tag: response.data.vast_tag,
              submitted: true,
            }),
          },
        ]);
      case "script":
        return setImg((prev: Img[]) => [
          ...prev,
          {
            url: response?.data?.html,
            size: response?.data?.size,
            creative_name: response?.data?.creative_name,
            uuid: response?.data?.id,
            languages: response?.data?.languages,
            html: response?.data?.html,
            submitted: true,
          },
        ]);
      default:
        break;
    }
  };

  const openFirstDrawer = () => {
    setFirstDrawerStates({ visible: true, selected: "" });
    setIsMultipleFilesSupported(false);
  };

  const cancelAllDrawers = () => resetUploadStates(false);

  const openSecondDrawer = () =>
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: true,
    });

  const changeUploadType = ({ target }: RadioChangeEvent) => {
    setFirstDrawerStates({ ...firstDrawerStates, selected: target.value });
    openSecondDrawer();
  };

  const closeSecondDrawer = () => {
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: false,
    });
    setFirstDrawerStates({ ...firstDrawerStates, selected: "" });
  };

  const resetUpdateStates = () => {
    setUpdateDrawerStates({
      visible: false,
      uuid: "",
      name: "",
      creativeLanguages: null,
    });
    form.resetFields();
  };

  const openUpdateDrawer = (uuid: string) => {
    setUpdateDrawerStates({
      visible: true,
      uuid,
      name:
        type === "VIDEO"
          ? video.find((v) => v.uuid === uuid)?.creative_name || ""
          : img.find((i) => i.uuid === uuid)?.creative_name || "",
      creativeLanguages:
        type === "VIDEO"
          ? video.find((v) => v.uuid === uuid)?.languages || null
          : img.find((i) => i.uuid === uuid)?.languages || null,
    });
  };

  const changeShowCreative = () =>
    setSecondDrawerStates({ ...secondDrawerStates, showCreative: false });

  const updateCreativeName = async () => {
    let success = false;
    const isSelectedCreative = updateDrawerStates.uuid !== "";

    const uploadedVideoID = video[video.length - 1]?.uuid;

    const uploadedDisplayID = img[img.length - 1]?.uuid;

    const currentVideoID = isSelectedCreative
      ? updateDrawerStates.uuid
      : uploadedVideoID;

    const currentDisplayID = isSelectedCreative
      ? updateDrawerStates.uuid
      : uploadedDisplayID;

    try {
      const response = await api
        .patch(`/creatives/${currentVideoID || currentDisplayID}`, {
          name: form.getFieldValue("creative_name"),
          ...(form.getFieldValue("creative_languages").length > 0 && {
            languages: form.getFieldValue("creative_languages"),
          }),
        })
        .then((response) => {
          if (type === "VIDEO") {
            setVideo((allVideos: Video[]) =>
              allVideos.map((obj: Video) => {
                if (obj.uuid === currentVideoID) {
                  return {
                    ...obj,
                    creative_name: response.data.creative_name,
                    languages: response.data.languages,
                    submitted: true,
                  };
                }
                return obj;
              })
            );
          } else {
            setImg((allImgs: Img[]) =>
              allImgs.map((obj: Img) => {
                if (obj.uuid === currentDisplayID) {
                  return {
                    ...obj,
                    creative_name: response.data.creative_name,
                    languages: response.data.languages,
                    submitted: true,
                  };
                }
                return obj;
              })
            );
          }
        });
      success = true;
      RetailNotification.showNotification(
        "success",
        "",
        t("components.notification.creativeUpload"),
        "bottomLeft"
      );
      return response;
    } catch (error) {
      console.error(error);
      success = false;
    } finally {
      isSelectedCreative ? resetUpdateStates() : resetUploadStates(success);
    }
  };

  const validateTextInput = (input: string) => {
    const isValidHtml = validateInput(input).isValid;
    if (firstDrawerStates.selected === "SCRIPT") setInputError(!isValidHtml);
    else setInputError(!isValidVAST.test(input));
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setSecondDrawerStates({
      ...secondDrawerStates,
      textValue: target.value,
      showCreative: target.value !== "",
    });
    validateTextInput(target.value);
  };

  const handleInputPaste = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = event.clipboardData.getData("text");
    setSecondDrawerStates((prevState) => {
      const newState = {
        ...prevState,
        showCreative: pastedText !== "",
      };
      validateInput(pastedText);
      return newState;
    });
  };

  const switchOnFinish = () => {
    switch (firstDrawerStates.selected) {
      case "VAST":
        return postVideoWithVast();
      case "SCRIPT":
        return postCreativeWithScript();
      default:
        return updateCreativeName();
    }
  };

  const prepareHtmlWithStyles = (htmlContent: string) => {
    const styleTag = `
      <style>
       html {
         width: 100%;
         height: 100%;
      }
        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        }
      </style>
    `;

    // Insert the style tag into the head of the user's HTML
    if (htmlContent.includes("<head>")) {
      return htmlContent.replace("<head>", `<head>${styleTag}`);
    }

    // If <head> is missing, inject it before <body>
    if (htmlContent.includes("<body>")) {
      return htmlContent.replace("<body>", `<head>${styleTag}</head><body>`);
    }

    // As a fallback, wrap the HTML in a complete document
    return `
      <!DOCTYPE html>
      <html>
      <head>${styleTag}</head>
      <body>${htmlContent}</body>
      </html>
    `;
  };

  return (
    <div className={cm.creativeFormRow}>
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: targeting === "AUTO_TARGETING" ? "6" : "7",
            title: t("components.campaignForm.firstStep.creativeTitle"),
            text: t("components.campaignForm.firstStep.videoText"),
          }}
        />
        <div
          className={cc([
            cm.creativeSideContainer,
            error && video.length === 0 ? cm.errorBorder : "",
          ])}
        >
          <VideoUploadBackground className={cm.creativeBackgroundIcon} />
          <CreativeIcon className={cm.creativeIcon} />
          <RetailTitle level={5} className={cm.creativeSideTitle}>
            {t("components.campaignForm.firstStep.videoSideTitle")}
          </RetailTitle>
          <RetailText
            size="xxs"
            weight="medium"
            className={cm.creativeSideText}
          >
            {t("components.campaignForm.firstStep.videoSideText")}
          </RetailText>
          <div className={cm.btnContainer}>
            <Upload {...props} multiple={true} className={cm.bulkUpload}>
              <RetailMainButton
                isPrimaryBorder={true}
                className={cm.creativeSideButton}
                onClick={() => setIsMultipleFilesSupported(true)}
              >
                {t("components.campaignForm.firstStep.bulk")}
              </RetailMainButton>
            </Upload>
            <RetailMainButton
              isPrimaryBorder={true}
              className={cm.creativeSideButton}
              onClick={openFirstDrawer}
            >
              {t("common.add")}
            </RetailMainButton>
          </div>
        </div>
      </RetailFormRow>
      {showWrapper && (
        <div className={cm.cardItemWrapper}>
          <section className={cm.cardItemContainer}>
            {type === "VIDEO"
              ? video.map((v) => (
                <VideoItem
                  singleVideo={v}
                  languages={languages}
                  deleteVideo={deleteVideo}
                  openUpdateDrawer={openUpdateDrawer}
                  key={v.uuid}
                />
              ))
              : img.map((i) => (
                <DisplayItem
                  singleImg={i}
                  languages={languages}
                  deleteCreative={deleteCreative}
                  openUpdateDrawer={openUpdateDrawer}
                  openPreview={open}
                  key={i.uuid}
                />
              ))}
          </section>
        </div>
      )}
      <RetailDrawer
        visible={firstDrawerStates.visible}
        onOk={openSecondDrawer}
        onClose={cancelAllDrawers}
        width={820}
        title={t("components.campaignForm.firstStep.firstDrawerTitle")}
        type="steps"
        disableOk={firstDrawerStates.selected === ""}
        footer={null}
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeTypeTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeTypeText")}
        </RetailText>
        <CreativeStepOptions
          t={t}
          changeUploadType={changeUploadType}
          firstDrawerStates={firstDrawerStates}
          adType={selectCampaignType(type)}
          allowHtml={allowHtml}
        />
      </RetailDrawer>
      {/*
       * Upload Drawers
       */}
      <RetailDrawer
        title={t(
          `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}_second_drawer_title`
        )}
        visible={secondDrawerStates.visible}
        onOk={form.submit}
        onClose={closeSecondDrawer}
        onCloseAll={cancelAllDrawers}
        width={772}
        type="add"
        cancelText={t("common.goBack")}
        disableOk={
          secondDrawerStates.name === "" ||
          (firstDrawerStates.selected === "SCRIPT" &&
            !secondDrawerStates.size) ||
          !secondDrawerStates.showCreative ||
          inputError ||
          img[img.length - 1]?.size_valid === false
        }
      >
        <CreativeNameForm
          onFinish={switchOnFinish}
          form={form}
          formStates={{
            name: secondDrawerStates.name,
            size: secondDrawerStates.size,
          }}
          handleSize={(value) =>
            setSecondDrawerStates({ ...secondDrawerStates, size: value })
          }
          languages={languages}
          selected={firstDrawerStates.selected}
          onChange={(e) =>
            setSecondDrawerStates({
              ...secondDrawerStates,
              name: e.target.value,
            })
          }
        />
        <RetailTitle level={5} noMargin>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}`
          )}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}_drawer`
          )}
        </RetailText>
        {firstDrawerStates.selected?.includes("UPLOAD") ? (
          <SecondDrawerUploadInner
            showCreative={secondDrawerStates.showCreative}
            video={video}
            changeShowCreative={changeShowCreative}
            img={img}
            adType={selectCampaignType(type)}
            setOpenUpload={setOpenUpload}
            loading={secondDrawerStates.isLoading}
            error={img[img.length - 1]?.size_valid === false}
            {...props}
          />
        ) : (
          <>
            {inputError && (
              <article className={`flex ${cm.errorContainer}`}>
                <RetailText size="xxxs" weight="medium">
                  {t(
                    `components.campaignForm.firstStep.errorStates.${firstDrawerStates.selected.toLowerCase()}`
                  )}
                </RetailText>
              </article>
            )}
            {firstDrawerStates.selected === "VAST" ? (
              <Input.TextArea
                value={secondDrawerStates.textValue}
                onChange={handleInputChange}
                onPaste={handleInputPaste}
                className={cc([
                  "floating",
                  cm.textarea,
                  inputError ? cm.errorInput : "",
                ])}
              />
            ) : (
              <Tabs className={cm.tabs}>
                <Tabs.TabPane
                  tab={t("components.campaignForm.firstStep.code")}
                  key="CODE"
                >
                  <Input.TextArea
                    value={secondDrawerStates.textValue}
                    onChange={handleInputChange}
                    onPaste={handleInputPaste}
                    className={cc([
                      "floating",
                      cm.textarea,
                      cm.textareaScript,
                      inputError ? cm.errorInput : "",
                    ])}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t("components.campaignForm.firstStep.preview")}
                  key="PREVIEW"
                >
                  <iframe
                    srcDoc={prepareHtmlWithStyles(
                      secondDrawerStates.textValue || ""
                    )}
                    title={secondDrawerStates.name}
                    sandbox="allow-scripts allow-same-origin"
                    width="100%"
                    height={validateInput(secondDrawerStates.textValue || "").isValid ? validateInput(secondDrawerStates.textValue || "").height : 300}
                    className={cm.iframe}
                    allowFullScreen={true}
                  />
                </Tabs.TabPane>
              </Tabs>
            )}
          </>
        )}
      </RetailDrawer>

      <RetailDrawer
        title={t("components.campaignForm.firstStep.updateDrawerTitle")}
        visible={updateDrawerStates.visible}
        onOk={form.submit}
        onClose={resetUpdateStates}
        mode="update"
        width={772}
      >
        <CreativeNameForm
          onFinish={updateCreativeName}
          form={form}
          formStates={updateDrawerStates}
          languages={languages}
          onChange={(e) =>
            setUpdateDrawerStates({
              ...updateDrawerStates,
              name: e.target.value,
            })
          }
        />
      </RetailDrawer>

      <CreativePreviewModal
        type={type === "VIDEO" ? "video" : "creative"}
        url={previewStates.url}
        html={previewStates.html}
        size={previewStates.size}
        visible={previewStates.visible}
        name={previewStates.name}
        onCancel={close}
      />
    </div>
  );
};

export default CreativeStep;
