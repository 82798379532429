import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Popover from "antd/lib/popover";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { MoreOutlined } from "@ant-design/icons/lib/icons";

import useApi from "api";
import { ReactComponent as PencilOutlined } from "assets/icons/pencilOutlined.svg";
import { ReactComponent as SearchOutlined } from "assets/icons/searchOutlined.svg";
import RetailDateColumn from "components/Column/RetailDateColumn";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNameColumn from "components/Column/RetailNameColumn";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailPageContainer from "components/Layout/RetailPageContainer";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailInvoiceModal from "components/Modal/RetailInvoiceModal";
import RetailTable from "components/Table/RetailTable";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import { transactionFilters } from "utils/filters";
import { validateNumber } from "utils/helpers";
import cm from "./style.module.scss";

const TransactionsPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);

  const [detailsVisible, setDetailsVisible] = useState(false);

  const [updateVisible, setUpdateVisible] = useState(false);

  const [details, setDetails] = useState<any>({ spend: null, budget: null });

  const [activeKey, setActiveKey] = useState("SPENDING");

  const [invoiceNo, setInvoiceNo] = useState<string | undefined>();

  const [id, setID] = useState<string | undefined>();

  const openDetails = (records: any) => {
    setDetailsVisible(true);
    setDetails({
      spend: records.spend_details,
      budget: records.budget_details,
    });
  };

  const openUpdate = (records: any) => {
    setUpdateVisible(true);
    setInvoiceNo(records.invoice_number);
    setID(records.id);
  };

  const invoiceNumberCharacterLimit = 30;

  const amountColInner = (value: number, transaction_type: string) => {
    switch (transaction_type) {
      case "SPEND":
      case "REFUND":
      case "COUPON_EXPIRED":
        return (
          <span className={`${cm.amount} ${cm.red}`}>
            - <RetailMoneyColumn value={value} />
          </span>
        );
      default:
        return (
          <span className={`${cm.amount} ${cm.green}`}>
            + <RetailMoneyColumn value={value} />
          </span>
        );
    }
  };

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "transaction_date":
        return <RetailDateColumn value={value} />;
      case "advertiser_name":
        return (
          <RetailNameColumn
            to={`/campaigns?adv=${records.advertiser_id}`}
            value={`${value} (${records.advertiser_id})`}
          />
        );
      case "transaction_type":
        return t(`pages.admin.transactions.${value?.toLowerCase()}`);
      case "description":
        return t(`pages.admin.transactions.${`${value?.toLowerCase()}_desc`}`);
      case "amount":
        return value ? amountColInner(value, records.transaction_type) : "-";
      case "spend_details":
        return (
          value && (
            <SearchOutlined
              className={cm.icon}
              onClick={() => openDetails(records)}
            />
          )
        );
      default:
        return value ? value : "-";
    }
  };

  const columns = (t: any) => [
    {
      title: "",
      dataIndex: "transaction_type",
      unsortable: true,
      render: (value: any, records: any) =>
        value === "BALANCE_UPDATED" && (
          <Popover
            content={
              <span
                className={cc(["flex", cm.text, cm.link])}
                onClick={() => openUpdate(records)}
              >
                <PencilOutlined /> {t("common.edit")}
              </span>
            }
            placement="leftTop"
            trigger="hover"
            arrowPointAtCenter
            overlayClassName={cm.popover}
          >
            <MoreOutlined style={{ cursor: "pointer", height: "1px" }} />
          </Popover>
        ),
    },
  ];

  const open = () => setVisible(true);

  const close = () => setVisible(false);

  const closeDetails = () => setDetailsVisible(false);

  const closeUpdate = () => {
    setUpdateVisible(false);
    setInvoiceNo("");
  };

  const onOk = async () => {
    try {
      await api.patch(`transactions/${id}`, {
        invoice_number: invoiceNo,
      });
      closeUpdate();
      queryClient.refetchQueries("table");
    } catch (e) {
      console.log(e);
    }
  };

  const tableConfig = {
    url: "transactions",
    isRelation: false,
    /* to: openUpdate, */
    filters: transactionFilters(t),
    renderColumns,
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          columnsForAdditionalRendering={() => columns(t)}
          tableConfig={tableConfig}
          rowSelection={undefined}
          button={{
            title: t("components.modal.campaignDetails.transactions"),
            onClick: open,
          }}
        />
        <RetailInvoiceModal
          type="TRANSACTIONS"
          subtitle={t("pages.admin.transactions.subtext")}
          visible={visible}
          setVisible={setVisible}
          onCancel={close}
        />
        <CampaignDetailsModal
          type="TRANSACTION_DETAILS"
          subtitle={t("pages.admin.transactions.detailsSubtext")}
          visible={detailsVisible}
          onCancel={closeDetails}
        >
          <Tabs activeKey={activeKey} onChange={(value) => setActiveKey(value)}>
            <Tabs.TabPane
              key="SPENDING"
              tab={t("pages.admin.transactions.spendTab")}
              className={cm.tab}
            >
              {details.spend &&
                details.spend.map((item: any, index: number) => (
                  <article
                    key={index}
                    className={cc(["flex", cm.spaceBetween, cm.line])}
                  >
                    <RetailText weight="bold" size="xs" className={cm.item}>
                      {item.campaign}
                    </RetailText>
                    <RetailText
                      weight="medium"
                      size="xs"
                      family="poppins"
                      className={cm.item}
                    >
                      - <RetailMoneyColumn value={item?.amount} />
                    </RetailText>
                  </article>
                ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              key="BUDGET"
              tab={t("pages.admin.transactions.budgetTab")}
              className={cm.tab}
            >
              {details.spend &&
                details.budget.map((item: any, index: number) => (
                  <article
                    key={index}
                    className={cc(["flex", cm.spaceBetween, cm.line])}
                  >
                    <RetailText weight="bold" size="xs" className={cm.item}>
                      {t(
                        `pages.admin.transactions.${item.resource?.toLowerCase()}`
                      )}
                    </RetailText>
                    <RetailText
                      weight="medium"
                      size="xs"
                      family="poppins"
                      className={cm.item}
                    >
                      - <RetailMoneyColumn value={item?.amount} />
                    </RetailText>
                  </article>
                ))}
            </Tabs.TabPane>
          </Tabs>
        </CampaignDetailsModal>
      </Row>
      <CampaignDetailsModal
        type="UPDATE_TRANSACTION"
        subtitle={t("pages.admin.transactions.update")}
        visible={updateVisible}
        disabled={
          invoiceNo === undefined ||
          invoiceNo.length > invoiceNumberCharacterLimit
        }
        onCancel={closeUpdate}
        onOk={onOk}
      >
        <Form
          initialValues={{
            invoice_no: invoiceNo,
          }}
          autoComplete="off"
        >
          <RetailTitle level={5} className={cm.title}>
            {t("pages.admin.transactions.title")}
          </RetailTitle>
          <RetailFormInput
            isFocused={invoiceNo !== undefined}
            label={t("pages.admin.transactions.title")}
            name="invoice_no"
            className="floating"
            rules={[
              {
                max: invoiceNumberCharacterLimit,
                message: t("pages.admin.transactions.invoiceWarning"),
              },
            ]}
          >
            <Input
              onChange={({ target }) => setInvoiceNo(target.value)}
              className={cc(["floating", cm.input])}
              value={invoiceNo}
              onKeyDownCapture={(e) => validateNumber(e)}
              data-test="campaign-form-name"
            />
          </RetailFormInput>
        </Form>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default TransactionsPage;
