import { useTranslation } from "react-i18next";
import { useLocation, useMatch } from "react-router-dom";
import Breadcrumb from "antd/lib/breadcrumb";
import { ReactComponent as RightOutlined } from "assets/icons/rightOutlined.svg";
import useAcademyCategoryFetch from "hooks/useAcademyCategoryFetch";
import useCampaignFetch from "hooks/useCampaignFetch";
import usePostFetch from "hooks/usePostFetch";
import useReportFetch from "hooks/useReportFetch";
import { bg_mode } from "utils/defaults";
import RetailAdvertiserViewLink from "components/Link/RetailAdvertiserViewLink";
import useDatasourceFetch from "hooks/useDatasourceFetch";

import cm from "../style.module.scss";

const RetailBrowserBreadcrumb = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const blog = useMatch("/academy/blog/:title/:slug");

  const { category } = useAcademyCategoryFetch();

  const switchBreadcrumb = () => {
    if (pathname.includes("report")) return "reports";
    if (pathname.includes("campaign")) return "campaigns";
    if (pathname.includes("console")) return "console";
    if (blog?.params !== undefined || category !== null) return "academy";
  };

  const { data: report } = useReportFetch(switchBreadcrumb() === "reports");

  const { data: campaign } = useCampaignFetch(
    switchBreadcrumb() === "campaigns"
  );
  const { data: datasource } = useDatasourceFetch(
    switchBreadcrumb() === "console"
  );

  const { post } = usePostFetch("academy");

  const nameData = () => {
    const breadcrumb = switchBreadcrumb();
    switch (breadcrumb) {
      case "reports":
        return report;
      case "campaigns":
        return campaign;
      case "console":
        return datasource;
      default:
        return null;
    }
  };

  const userType = pathname.includes("admin") ? "admin" : "user";

  const switchLink = () => {
    if (userType === "user") {
      return `/${switchBreadcrumb()}`;
    }
    return `/admin/${switchBreadcrumb()}`;
  };

  return (
    <Breadcrumb
      separator={<RightOutlined className={cm[`${bg_mode}Separator`]} />}
    >
      <Breadcrumb.Item className={cm.breadcrumb}>
        <RetailAdvertiserViewLink to={switchLink()}>
          {t(`sider.${userType}.${switchBreadcrumb()}`)}
        </RetailAdvertiserViewLink>
      </Breadcrumb.Item>
      {blog?.params !== undefined && (
        <Breadcrumb.Item className={cm.breadcrumb}>
          <RetailAdvertiserViewLink to={`/academy/blog/${blog.params.title}`}>
            {post?.categories[0].title}
          </RetailAdvertiserViewLink>
        </Breadcrumb.Item>
      )}
      <Breadcrumb.Item className={cm.titleCrumb}>
        {nameData()?.data?.name ||
          nameData()?.data?.report_name ||
          (post ? post.title : category?.title)}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default RetailBrowserBreadcrumb;
